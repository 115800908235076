import React, { useState, useEffect } from "react";

const useAudio = url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [audio, playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        }
    }, [audio]);

    return [playing, toggle];
};

const Player = ({url}) => {
    const [playing, toggle] = useAudio(url);

    return (
        <>
            <a href={'#music'} onClick={toggle}><b>{playing ? "Stop us..." : "Feel us..."}</b></a>
        </>
    )
}

export default Player;