import React, {useState, useEffect} from "react";
import Player from "./Player";

const RandomPlayer = () => {
    const [dataIndex, setDataIndex] = useState(0);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchMusicData = () => {
        console.log("Loading")
        fetch("/music.dat")
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status}`
                    );
                }
                return response.json();
            })
            .then(data => {
                setData(data)
                randomMusic(data)
            }).catch((err) => {
            console.log(err.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const randomMusic = (music) => {
        if(!music) return;
        const len = music.length;
        setDataIndex(Math.floor(Math.random() * len));
    };

    useEffect(() => {
            fetchMusicData()
    }, [])

    return (<>

            {loading && <div>A moment please...</div>}
            {data && <Player url={data[dataIndex]}></Player>}
        </>
    )
}

export default RandomPlayer;