import logo from './favicon.png';
import './App.css';
import React from "react";
import RandomPlayer from "./components/RandomPlayer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We are a collective.
        </p>
          <p>
              <a href={"/map/"}><b>See us...</b></a>
          </p>
          <RandomPlayer></RandomPlayer>
      </header>
    </div>
  );
}

export default App;
